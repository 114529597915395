<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券</el-breadcrumb-item>
      <el-breadcrumb-item>商家额度查看</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pkIds"
            filterable
            placeholder="请选择"
            style="width: 200px"
            size="small"
            @change="pkidchange"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >商家名称：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入商家名称"
            v-model="query.shopName"
            clearable
          />
        </label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="shopInfoPkcx"
          >查询</el-button
        >
        <el-button size="small" @click="shopInfoPkcz()">重置</el-button>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        :loading="isShowImprot"
        @click="improtClick"
        >导出</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="shopInfo.shopName"
            label="商家名称"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="totalCouponMoney"
            label="可用总额度"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="pkInfo.pkName"
            label="停车场"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="230"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                v-if="!$cookies.get('user')"
                @click="addClickIsShow(scope)"
                size="mini"
                icon="el-icon-plus"
                >添加购买记录</el-button
              >
              <el-button
                type="text"
                @click="queryClick(scope)"
                size="mini"
                icon="el-icon-share"
                >查看购买记录</el-button
              >
              <el-button
                type="text"
                @click="queryClick2(scope)"
                size="mini"
                icon="el-icon-share"
                >查看额度明细</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加 -->
    <el-dialog
      title="添加商家购买记录"
      :visible.sync="isShowAdd1"
      @close="addClose1"
      width="35%"
      v-el-drag-dialog
    >
      <el-form
        :model="addForm1"
        :rules="formRules"
        ref="addRefs"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="当前停车场">
          <span>{{ addpkId }}</span>
        </el-form-item>
        <el-form-item label="商家名称" prop="shopId">
          <span>{{ shopName }}</span>
        </el-form-item>
        <el-form-item label="购买金额(元)" prop="totalCouponMoney">
          <el-input
            v-model="addForm1.totalCouponMoney"
            @input="relationChange"
            placeholder="请输入购买金额(元)"
          ></el-input>
        </el-form-item>
        <el-form-item label="购买后总额度" prop="status">
          <span>{{ totalCouponMoney ? totalCouponMoney + '元' : '0元' }}</span>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="addForm1.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="购买折扣" prop="discountValue">
          <el-input
            v-model="addForm1.discountValue"
            placeholder="请输入购买折扣"
            @input="discountChange"
          ></el-input>
        </el-form-item>
        <el-form-item label="实收金额" prop="actualMoney">
          <el-input
            v-model="addForm1.actualMoney"
            placeholder="请输入实收金额"
            oninput="value=value.replace(/[^\d]/g,'')"
            disabled
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowAdd1 = false">取 消</el-button>
        <el-button type="primary" @click="addClick1">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  shopInfoPk,
  shopInfo,
  shopInfoPkje,
  findByMoneyxx,
  exportShopInfopk,
} from '@/api/coupon.js'
import { findPkInfoSelect } from '@/api/Parkinglot.js'
export default {
  data() {
    var validateName = (rule, value, callback) => {
      let result
      if (value == undefined || value == '') {
        callback()
        return
      }
      var express = /^0\.\d{1,2}$/
      result = express.test(value) // 满足条件时候 正则结果是true
      if (result) {
        callback()
      } else {
        callback(new Error('请输入正确折扣格式,如10%按（0.1）填写'))
      }
    }
    return {
      isShowImprot: false,
      loading: false,
      isShowAdd1: false,
      isShowData: false,
      isShowQuery: false,
      tableData: [],
      tableDatatk: [],
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pkIds: undefined, // 停车场id
        shopName: undefined, // 商户名称
      },
      addForm: {
        username: '', // 账号
        password: '', // 密码
        pkIds: '', // 停车场
        shopName: '', // 商家名称
        linkMan: '', // 联系人
        tel: '', // 联系电话
        address: '', // 详细地址
        remarks: '', // 备注
      },
      addForm1: {
        shopId: undefined, // 商家id
        pkId: undefined, // 停车场id
        maxConfigCouponMoney: undefined,
        totalCouponMoney: undefined, // 金额
        remark: undefined, // 备注
        discountValue: undefined, //购买折扣
        actualMoney: undefined, //实收金额
      },
      formRules: {
        // 添加表单的验证规则
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        pkId: [
          { required: true, message: '请选择停车场名称', trigger: 'change' },
        ],
        pkIds: [
          { required: true, message: '请选择停车场名称', trigger: 'change' },
        ],
        shopName: [
          { required: true, message: '请输入总车位数', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        discountValue: [
          {
            required: false,
            message: '请输入正确折扣格式,如10%按（0.1）填写',
            trigger: 'change',
          },
          { validator: validateName, trigger: 'change' },
        ],
        totalCouponMoney: [
          { required: true, message: '请输入购买金额', trigger: 'blur' },
        ],
      },
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
      typeCar: [],
      typeColor: [],
      chargeType: [],
      shopName: '',
      totalCouponMoney1: '',
      totalCouponMoney: '',
      addpkId: undefined,
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  methods: {
    pkidchange() {
      // 停车场值变化自动查询
      this.shopInfoPkcx()
    },
    improtClick() {
      // 导出
      this.isShowImprot = true
      exportShopInfopk(this.query).then((res) => {
        if (res.data.code === '200') {
          window.open(res.data.data)
        }
        this.isShowImprot = false
      })
    },
    shopInfoPk() {
      // 列表
      shopInfoPk(this.query).then((res) => {
        this.tableData = res.data.data.list
        this.total = res.data.data.total
        this.isShowData = true
        this.loading = false
      })
    },
    shopInfoPkcx() {
      // 查询
      this.query.current = 1
      this.shopInfoPk()
    },
    shopInfoPkcz() {
      // 重置
      this.query.pkId = this.PkInfoSelect[0].id
      this.query.shopName = ''
      this.shopInfoPk()
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          // 默认查询第一个停车场
          this.query.pkIds = this.PkInfoSelect[0].id
          // this.query.pkIds = this.PkInfoSelect.map((item) => {
          //   return item.id;
          // });
          this.shopInfoPk()
        }
      })
    },
    queryClick(scope) {
      // 查看商家购买记录
      this.$router.push({
        path: 'Purchases',
        query: { shopId: scope.row.shopId, pkId: scope.row.pkId },
      })
    },

    queryClick2(scope) {
      // 查看额度明细
      this.$router.push({
        path: 'MoneyLog',
        query: { shopId: scope.row.shopId, pkId: scope.row.pkId },
      })
    },

    handleSizeChange(val) {
      this.loading = true
      this.query.size = val
      this.shopInfoPk()
    },
    handleCurrentChange(val) {
      this.loading = true
      this.query.current = val
      this.shopInfoPk()
    },
    addClickIsShow(scope) {
      // 添加购买记录弹框
      this.isShowAdd1 = true
      this.addForm1.shopId = scope.row.shopId
      this.shopName = scope.row.shopInfo.shopName
      this.addpkId = scope.row.pkInfo.pkName
      this.query.pkId = scope.row.pkId
      const obj = {
        pkId: scope.row.pkId,
        shopId: scope.row.shopId,
      }
      findByMoneyxx(obj).then((res) => {
        this.totalCouponMoney1 = res.data.data.totalCouponMoney
        this.totalCouponMoney = this.totalCouponMoney1
        this.addForm1.maxConfigCouponMoney = res.data.data.maxConfigCouponMoney
      })
    },
    // 监听添加用户对话框的关闭事件 清空input数据
    addClose1() {
      this.$refs.addRefs.resetFields()
    },
    addClick1() {
      // 添加购买记录
      this.addForm1.pkId = this.query.pkId
      this.$refs.addRefs.validate((valid) => {
        if (!valid) return
        shopInfoPkje(this.addForm1).then((res) => {
          if (res.data.code === '200') {
            this.isShowAdd1 = false
            this.$message.success('添加成功')
            this.shopInfoPk()
          }
        })
      })
    },
    discountChange(value) {
      // 使用正则表达式验证输入是否为小数或整数
      const pattern = /^0\.\d{1,2}$/
      if (pattern.test(value)) {
        if (this.addForm1.totalCouponMoney) {
          let afterDiscount = this.addForm1.totalCouponMoney * value

          this.addForm1.actualMoney = afterDiscount
        }
      }
    },
    relationChange(value) {
      // 使用正则表达式验证输入是否为小数或整数
      const pattern = /^-?\d+(?:\.\d+)?$/
      if (pattern.test(value)) {
        // 添加购买记录输入购买金额和购买后的额度绑定
        this.totalCouponMoney = this.totalCouponMoney1
        this.totalCouponMoney =
          parseInt(this.totalCouponMoney) +
          parseInt(this.addForm1.totalCouponMoney)

        if (this.addForm1.discountValue) {
          let afterDiscount = value * this.addForm1.discountValue

          this.addForm1.actualMoney = afterDiscount
        } else {
          this.addForm1.actualMoney = value
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
